import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Grid, Alert, Paper, Chip } from '@mui/material';
import { Venue } from '../../../../shared/models';
import { containsContactInfo, containsEmailOrPhoneNumber } from '../../lib/util';

interface BasicVenueInfoProps {
    data: Partial<Venue>;
    onNext: (venueData: Partial<Venue>) => void;
    place?: google.maps.places.PlaceResult | null;
    nextButtonText?: string;
    missingFields?: string[];
}

const BasicVenueInfo: React.FC<BasicVenueInfoProps> = ({ data, onNext, place, nextButtonText = 'Save and Continue', missingFields = [] }) => {
    const [venueData, setVenueData] = useState<Partial<Venue>>(data);
    const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

    useEffect(() => {
        if (place) {
            setVenueData(prevData => ({
                ...prevData,
                Name: place.name || prevData.Name || '',
                Address: place.formatted_address || prevData.Address || '',
                Number: place.formatted_phone_number || prevData.Number || '',
                PhotoUrls: place.photos?.map(photo => photo.getUrl()) || prevData.PhotoUrls || [],
            }));
        }
    }, [place]);

    useEffect(() => {
        setVenueData(prevData => ({ ...prevData, ...data }));
    }, [data]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Skip contact info validation for these fields
        const contactAllowedFields = ['Number', 'AdminEmailsSeparatedByComma'];

        if (!contactAllowedFields.includes(name) && containsEmailOrPhoneNumber(value)) {
            setFieldErrors(prev => ({
                ...prev,
                [name]: 'Please do not include contact information in this field'
            }));
        } else {
            setFieldErrors(prev => {
                const newErrors = { ...prev };
                delete newErrors[name];
                return newErrors;
            });
        }

        setVenueData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (Object.keys(fieldErrors).length === 0) {
            const formattedVenueData = {
                ...venueData,
            };
            onNext(formattedVenueData);
        }
    };

    // Common TextField styles
    const textFieldSx = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&.Mui-focused': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.3)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d1c4e9',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
        },
        '& .MuiInputBase-input': {
            color: 'rgba(255, 255, 255, 0.9)',
        }
    };

    // Enhanced TextField styles for missing fields
    const missingFieldSx = {
        ...textFieldSx,
        '& .MuiOutlinedInput-root': {
            ...textFieldSx['& .MuiOutlinedInput-root'],
            backgroundColor: 'rgba(224, 177, 241, 0.15)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(224, 177, 241, 0.7)',
            borderWidth: 2,
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(224, 177, 241, 0.9)',
            fontWeight: 'bold',
        },
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Basic Venue Information
            </Typography>
            {place && place.place_id && (
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ color: 'lightgray', mb: 2 }}>
                        View on Google Maps to read more about the venue:
                        <Button
                            variant="contained"
                            color="info"
                            href={`https://www.google.com/maps/place/?q=place_id:${place.place_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ ml: 2, color: 'white', borderColor: 'white' }}
                        >
                            Open
                        </Button>
                    </Typography>
                </Grid>
            )}
            <Grid container spacing={2}>
                {/* Make missing fields appear first and with highlighted styling */}
                {missingFields.includes('Name') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Name"
                            label="Venue Name"
                            value={venueData.Name || ''}
                            onChange={handleChange}
                            sx={missingFieldSx}
                            id="venue-name"
                            error={!!fieldErrors.Name}
                            helperText={fieldErrors.Name || "Required field - please enter the venue name"}
                            FormHelperTextProps={{
                                sx: {
                                    color: fieldErrors.Name ? 'error.main' : '#E0B1F1',
                                    fontWeight: 'medium'
                                }
                            }}
                        />
                    </Grid>
                )}

                {missingFields.includes('Address') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Address"
                            label="Address"
                            value={venueData.Address || ''}
                            onChange={handleChange}
                            sx={missingFieldSx}
                            helperText="Required field - please enter the venue address"
                            FormHelperTextProps={{
                                sx: {
                                    color: '#E0B1F1',
                                    fontWeight: 'medium'
                                }
                            }}
                        />
                    </Grid>
                )}

                {missingFields.includes('Description') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Description"
                            label="Description"
                            multiline
                            rows={4}
                            value={venueData.Description || ''}
                            onChange={handleChange}
                            sx={missingFieldSx}
                            error={!!fieldErrors.Description}
                            helperText={fieldErrors.Description || "Required field - please enter a venue description"}
                            FormHelperTextProps={{
                                sx: {
                                    color: fieldErrors.Description ? 'error.main' : '#E0B1F1',
                                    fontWeight: 'medium'
                                }
                            }}
                        />
                    </Grid>
                )}

                {missingFields.includes('Subtitle') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Subtitle"
                            label="Subtitle"
                            value={venueData.Subtitle || ''}
                            onChange={handleChange}
                            sx={missingFieldSx}
                            helperText="Required field - please enter a brief subtitle for your venue"
                            FormHelperTextProps={{
                                sx: {
                                    color: '#E0B1F1',
                                    fontWeight: 'medium'
                                }
                            }}
                        />
                    </Grid>
                )}

                {missingFields.includes('Phone Number') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Number"
                            label="Contact Phone Number"
                            value={venueData.Number || ''}
                            onChange={handleChange}
                            sx={missingFieldSx}
                            helperText="Required field - please enter the venue contact number"
                            FormHelperTextProps={{
                                sx: {
                                    color: '#E0B1F1',
                                    fontWeight: 'medium'
                                }
                            }}
                        />
                    </Grid>
                )}

                {/* Regular fields (not missing) */}
                {!missingFields.includes('Name') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Name"
                            label="Venue Name"
                            value={venueData.Name || ''}
                            onChange={handleChange}
                            sx={textFieldSx}
                            id="venue-name"
                            error={!!fieldErrors.Name}
                            helperText={fieldErrors.Name}
                            FormHelperTextProps={{
                                sx: {
                                    color: fieldErrors.Name ? 'error.main' : 'lightgray'
                                }
                            }}
                        />
                    </Grid>
                )}

                {!missingFields.includes('Address') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Address"
                            label="Address"
                            value={venueData.Address || ''}
                            onChange={handleChange}
                            sx={textFieldSx}
                        />
                    </Grid>
                )}

                {!missingFields.includes('Description') && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="Description"
                            label="Description"
                            multiline
                            rows={4}
                            value={venueData.Description || ''}
                            onChange={handleChange}
                            sx={textFieldSx}
                            error={!!fieldErrors.Description}
                            helperText={fieldErrors.Description}
                            FormHelperTextProps={{
                                sx: {
                                    color: fieldErrors.Description ? 'error.main' : 'lightgray'
                                }
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="CheckoutPageDescription"
                        label="Checkout Page Description"
                        multiline
                        rows={3}
                        value={venueData.CheckoutPageDescription || ''}
                        onChange={handleChange}
                        sx={textFieldSx}
                        helperText="This description will be shown on the checkout page. Include any complimentary items or special offerings."
                        FormHelperTextProps={{ sx: { color: 'lightgray' } }}
                    />
                </Grid>

                {!missingFields.includes('Phone Number') && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            name="Number"
                            label="Contact Phone Number"
                            value={venueData.Number || ''}
                            onChange={handleChange}
                            sx={textFieldSx}
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={missingFields.includes('Phone Number') ? 12 : 6}>
                    <TextField
                        fullWidth
                        name="AdminEmailsSeparatedByComma"
                        label="Contact Email Addresses"
                        value={venueData.AdminEmailsSeparatedByComma || ''}
                        onChange={handleChange}
                        sx={textFieldSx}
                        helperText="Separate multiple email addresses with commas. These will receive booking notifications."
                        FormHelperTextProps={{ sx: { color: 'lightgray' } }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name="HostFirstNameLastInitial"
                        label="Host Name"
                        value={venueData.HostFirstNameLastInitial || ''}
                        onChange={handleChange}
                        sx={textFieldSx}
                        helperText="Format: First Name Last Initial (e.g., John D.)"
                        FormHelperTextProps={{ sx: { color: 'lightgray' } }}
                    />
                </Grid>

                {!missingFields.includes('Subtitle') && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            name="Subtitle"
                            label="Subtitle"
                            value={venueData.Subtitle || ''}
                            onChange={handleChange}
                            sx={textFieldSx}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="BookingPolicy"
                        label="Booking & Cancellation Policy"
                        multiline
                        rows={4}
                        value={venueData.BookingPolicy || ''}
                        onChange={handleChange}
                        sx={textFieldSx}
                        helperText="Describe your booking and cancellation policies, including any deposit requirements and refund conditions."
                        FormHelperTextProps={{ sx: { color: 'lightgray' } }}
                    />
                </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type="submit"
                    sx={{
                        backgroundColor: '#d1c4e9',
                        color: '#2c2c54',
                        '&:hover': {
                            backgroundColor: '#b39ddb',
                        },
                        minWidth: '100px',
                        fontWeight: 'bold',
                    }}
                >
                    {nextButtonText}
                </Button>
            </Box>
        </Box>
    );
};

export default BasicVenueInfo;