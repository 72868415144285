'use client';

import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, TextField, Checkbox, FormControlLabel, IconButton, Paper, Chip } from '@mui/material';
import { Venue, Section, DayOfWeek } from '../../../../shared/models';
import {
    TimePicker,
    LocalizationProvider,
    loadAdapterDateFns
} from '../../components/lazy/date-pickers';
import Link from 'next/link';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkWithLoading from '../../components/link_with_loading';

interface VenueAvailabilitiesProps {
    data: Partial<Venue>;
    onNext: (venueData: Partial<Venue>) => void;
    place?: google.maps.places.PlaceResult | null;
    missingFields?: string[];
}

const daysOfWeek: DayOfWeek[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export function VenueAvailabilities({ data, onNext, place, missingFields }: VenueAvailabilitiesProps) {
    const [availability, setAvailability] = useState<Record<DayOfWeek, Venue['Availability'][DayOfWeek]>>(
        data.Availability || {} as Record<DayOfWeek, Venue['Availability'][DayOfWeek]>
    );
    const [adapter, setAdapter] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function loadAdapter() {
            setIsLoading(true);
            const AdapterClass = await loadAdapterDateFns();
            setAdapter(new AdapterClass());
            setIsLoading(false);
        }

        loadAdapter();
    }, []);

    useEffect(() => {
        const newAvailability: Record<DayOfWeek, Venue['Availability'][DayOfWeek]> = { ...availability };
        let hasChanges = false;

        daysOfWeek.forEach((day, index) => {
            if (!newAvailability[day]) {
                hasChanges = true;
                if (data.Availability && data.Availability[day]) {
                    // Prioritize data from Venue
                    newAvailability[day] = {
                        ...data.Availability[day],
                        IsOpen: data.Availability[day].IsOpen ?? true,
                        Slots: data.Availability[day].Slots || [],
                        Times: data.Availability[day].Times || [{
                            StartTime: data.Availability[day].StartTime,
                            EndTime: data.Availability[day].EndTime
                        }]
                    };
                } else if (place && place.opening_hours && place.opening_hours.periods) {
                    // Use Google Place data as fallback
                    const dayHours = place.opening_hours.periods.find(period => period.open.day === (index + 1) % 7);
                    if (dayHours && dayHours.open && dayHours.close && dayHours.open.time && dayHours.close.time) {
                        const openTime = new Date(`2000-01-01T${dayHours.open.time.slice(0, 2)}:${dayHours.open.time.slice(2)}`);
                        const closeTime = new Date(`2000-01-01T${dayHours.close.time.slice(0, 2)}:${dayHours.close.time.slice(2)}`);
                        newAvailability[day] = {
                            StartTime: openTime,
                            EndTime: closeTime,
                            IsOpen: true,
                            Slots: [],
                            Times: [{
                                StartTime: openTime,
                                EndTime: closeTime
                            }]
                        };
                    }
                } else {
                    // Default times if no venue data and no place data
                    let openTime = new Date();
                    openTime.setHours(9, 0, 0, 0);
                    let closeTime = new Date();
                    closeTime.setHours(17, 0, 0, 0);
                    newAvailability[day] = {
                        StartTime: openTime,
                        EndTime: closeTime,
                        IsOpen: true,
                        Slots: [],
                        Times: [{
                            StartTime: openTime,
                            EndTime: closeTime
                        }]
                    };
                }
            }
        });

        if (hasChanges) {
            setAvailability(newAvailability);
        }
    }, [data.Availability, place]);

    const handleTimeChange = (day: DayOfWeek, type: 'StartTime' | 'EndTime', newTime: Date | null) => {
        if (newTime) {
            // Round minutes to nearest 30
            const minutes = Math.round(newTime.getMinutes() / 30) * 30;
            newTime.setMinutes(minutes);
            setAvailability(prev => ({
                ...prev,
                [day]: {
                    ...prev[day],
                    [type]: newTime,
                    Times: prev[day].Times?.map(time => ({
                        ...time,
                        [type]: newTime
                    })) || [{
                        StartTime: type === 'StartTime' ? newTime : prev[day].StartTime,
                        EndTime: type === 'EndTime' ? newTime : prev[day].EndTime
                    }]
                },
            }));
        }
    };

    const handleIsOpenChange = (day: DayOfWeek, checked: boolean) => {
        setAvailability(prev => {
            if (checked) {
                let openTime = new Date();
                openTime.setHours(9, 0, 0, 0);
                let closeTime = new Date();
                closeTime.setHours(17, 0, 0, 0);
                return {
                    ...prev,
                    [day]: {
                        StartTime: openTime,
                        EndTime: closeTime,
                        IsOpen: true,
                        Slots: [],
                        Times: [{
                            StartTime: openTime,
                            EndTime: closeTime
                        }]
                    }
                };
            } else {
                const newAvailability = { ...prev };
                if (newAvailability[day]) {
                    newAvailability[day] = {
                        ...newAvailability[day],
                        IsOpen: false
                    };
                }
                return newAvailability;
            }
        });
    };

    const handleAddSlot = (day: DayOfWeek) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                Slots: [
                    ...(prev[day].Slots || []),
                    { StartTime: new Date(), EndTime: new Date(), IsOpen: true },
                ],
            },
        }));
    };

    const handleRemoveSlot = (day: DayOfWeek, index: number) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                Slots: prev[day].Slots?.filter((_, i) => i !== index),
            },
        }));
    };

    const handleSlotTimeChange = (day: DayOfWeek, index: number, type: 'StartTime' | 'EndTime', newTime: Date | null) => {
        if (newTime) {
            // Round minutes to nearest 30
            const minutes = Math.round(newTime.getMinutes() / 30) * 30;
            newTime.setMinutes(minutes);
            setAvailability(prev => ({
                ...prev,
                [day]: {
                    ...prev[day],
                    Slots: prev[day].Slots?.map((slot, i) =>
                        i === index ? { ...slot, [type]: newTime } : slot
                    ),
                },
            }));
        }
    };

    const handleSubmit = () => {
        onNext({ ...data, Availability: availability });
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <LocalizationProvider dateAdapter={adapter?.constructor as any}>
            <Box>
                <Typography variant="h6" gutterBottom sx={{ color: '#fff', mb: 3 }}>
                    Set Venue Availability
                </Typography>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={4}>
                        {place?.opening_hours?.weekday_text && (
                            <Box sx={{ mb: 3, p: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 2, height: '100%' }}>
                                <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>
                                    Google Places Opening Hours:
                                </Typography>
                                {place.opening_hours.weekday_text.map((dayHours, index) => (
                                    <Typography key={index} variant="body2" sx={{ color: '#a094b7' }}>
                                        {dayHours}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ mb: 3, p: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 2, height: '100%' }}>
                            <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
                                We've pre-filled the venue times based on existing data or Google Places data. Verify or update times
                            </Typography>
                            <LinkWithLoading href={`https://www.google.com/search?q=${encodeURIComponent(data.Name + ' rent')}`} target="_blank" rel="noopener noreferrer">
                                <Typography sx={{ color: '#6c63ff', textDecoration: 'underline', '&:hover': { color: '#8f7fff' } }}>
                                    Search for {data.Name} on Google
                                </Typography>
                            </LinkWithLoading>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {daysOfWeek.map((day) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={day}>
                            <Box sx={{ p: 2, border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: 1, bgcolor: 'lightgray' }}>
                                <Typography variant="subtitle2" sx={{ color: 'black', mb: 1 }}>{day}</Typography>
                                {availability[day] ? (
                                    <>
                                        <Grid container spacing={1}>
                                            {(availability[day]?.Times || [{ StartTime: availability[day]?.StartTime, EndTime: availability[day]?.EndTime }]).map((time, index) => (
                                                <Grid container spacing={1} key={index}>
                                                    <Grid item xs={5}>
                                                        <TimePicker
                                                            label="Open"
                                                            value={time.StartTime || null}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    const newTimes = [...(availability[day]?.Times || [])];
                                                                    newTimes[index] = { ...newTimes[index], StartTime: newValue };
                                                                    setAvailability(prev => ({
                                                                        ...prev,
                                                                        [day]: {
                                                                            ...prev[day],
                                                                            StartTime: newValue, // For backwards compatibility
                                                                            Times: newTimes
                                                                        }
                                                                    }));
                                                                }
                                                            }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            views={['hours', 'minutes']}
                                                            minutesStep={30}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TimePicker
                                                            label="Close"
                                                            value={time.EndTime || null}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    const newTimes = [...(availability[day]?.Times || [])];
                                                                    newTimes[index] = { ...newTimes[index], EndTime: newValue };
                                                                    setAvailability(prev => ({
                                                                        ...prev,
                                                                        [day]: {
                                                                            ...prev[day],
                                                                            EndTime: newValue, // For backwards compatibility
                                                                            Times: newTimes
                                                                        }
                                                                    }));
                                                                }
                                                            }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            views={['hours', 'minutes']}
                                                            minutesStep={30}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton
                                                            onClick={() => {
                                                                const newTimes = availability[day]?.Times?.filter((_, i) => i !== index) || [];
                                                                setAvailability(prev => ({
                                                                    ...prev,
                                                                    [day]: {
                                                                        ...prev[day],
                                                                        Times: newTimes,
                                                                        StartTime: newTimes[0]?.StartTime || prev[day].StartTime,
                                                                        EndTime: newTimes[0]?.EndTime || prev[day].EndTime
                                                                    }
                                                                }));
                                                            }}
                                                            sx={{ color: '#4a148c' }}
                                                            disabled={!availability[day]?.Times || availability[day]?.Times?.length <= 1}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12}>
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    onClick={() => {
                                                        setAvailability(prev => ({
                                                            ...prev,
                                                            [day]: {
                                                                ...prev[day],
                                                                Times: [...(prev[day]?.Times || []), {
                                                                    StartTime: new Date(),
                                                                    EndTime: new Date()
                                                                }]
                                                            }
                                                        }));
                                                    }}
                                                    sx={{ mt: 1, color: '#4a148c' }}
                                                >
                                                    Add Time Range
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={availability[day]?.IsOpen ?? true}
                                                    onChange={(e) => handleIsOpenChange(day, e.target.checked)}
                                                    sx={{ color: '#4a148c' }}
                                                />
                                            }
                                            label="Open"
                                            sx={{ mt: 1, color: 'black' }}
                                        />
                                        <Typography variant="subtitle2" sx={{ color: 'black', mt: 2, mb: 1 }}>Or Optional Time Slots:</Typography>
                                        {availability[day].Slots?.map((slot, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <TimePicker
                                                    label="Start"
                                                    value={slot.StartTime}
                                                    onChange={(newValue) => handleSlotTimeChange(day, index, 'StartTime', newValue)}
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    views={['hours', 'minutes']}
                                                    minutesStep={30}
                                                />
                                                <TimePicker
                                                    label="End"
                                                    value={slot.EndTime}
                                                    onChange={(newValue) => handleSlotTimeChange(day, index, 'EndTime', newValue)}
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    views={['hours', 'minutes']}
                                                    minutesStep={30}
                                                />
                                                <IconButton onClick={() => handleRemoveSlot(day, index)} sx={{ color: '#4a148c' }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ))}
                                        <Button
                                            startIcon={<AddIcon />}
                                            onClick={() => handleAddSlot(day)}
                                            sx={{ mt: 1, color: '#4a148c' }}
                                        >
                                            Add Slot
                                        </Button>
                                    </>
                                ) : (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <IconButton onClick={() => handleIsOpenChange(day, true)} sx={{ color: '#4a148c' }}>
                                            <AddIcon />
                                            <Typography variant="body2" sx={{ ml: 1 }}>Add Hours</Typography>
                                        </IconButton>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                            bgcolor: '#d1c4e9',
                            color: '#4a148c',
                            '&:hover': {
                                bgcolor: '#b39ddb'
                            }
                        }}
                    >
                        Save and Continue
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
}

export default VenueAvailabilities;
