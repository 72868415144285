/**
 * Transforms image URLs for optimization using imgix
 * @param {string} src - Original image URL or path
 * @param {number} width - Desired width of the image
 * @param {number} quality - Optional quality parameter
 * @returns {string} - Transformed imgix URL
 */
export function getOptimizedImageUrl(src, width, quality) {
  if (!src) return '';
  
  try {
    const isFullUrl = src.startsWith('http://') || src.startsWith('https://');
    let url;

    // Handle new venue image path pattern: 'venues/venue_id/section_id/image.whatever'
    if (!isFullUrl && (src.startsWith('venues/') || src.startsWith('blog_images/'))) {
      // For venue images using the new pattern, use direct path approach
      url = new URL(`https://getlitty.imgix.net/${src}`);
    }
    else if (isFullUrl) {

      if (src.includes('firebasestorage.googleapis.com')) {
        // Handle Firebase Storage URLs
        const pathRegex = /o\/([^?]+)/;
        const match = src.match(pathRegex);
        
        if (match && match[1]) {
          const decodedPath = decodeURIComponent(match[1]);
          url = new URL(`https://getlitty.imgix.net/${decodedPath}`);
        } else {
          // Fallback to default behavior if path extraction fails
          url = new URL('https://getlitty.imgix.net');
          // Use encodeURIComponent to ensure consistent encoding
          url.searchParams.set('url', src);
        }
      } else if (src.includes('storage.googleapis.com')) {
        // Check if this is a venue path in GCS
        // Fix regex to properly capture all venue paths including those with subfolders and filenames with any characters
        const venuePathMatch = src.match(/storage\.googleapis\.com\/[^\/]+\/(venues\/[^?]*)/);
        
        if (venuePathMatch && venuePathMatch[1]) {
          // For venue paths, use the direct path approach
          url = new URL(`https://getlitty.imgix.net/${venuePathMatch[1]}`);
        } else {
          try {
            // Extract the relevant parts from the GCS URL
            const gcsUrl = new URL(src);
            const pathParts = gcsUrl.pathname.split('/');
            const bucket = pathParts[1]; // First part after the initial slash is the bucket name
            
            // Get the object path and ensure proper encoding
            // We need to handle each path segment separately to properly encode special characters
            const objectPathSegments = pathParts.slice(2);
            
            // If the URL has query parameters (like a signed URL), we need to use the proxy approach
            if (gcsUrl.search) {
              url = new URL('https://getlitty.imgix.net');
              
              // First decode the URL to make sure we don't double-encode
              // This helps prevent mixed encoding issues (+ vs %20 for spaces)
              const decodedSrc = decodeURIComponent(src);
              
              // Manually create a proper URL-encoded string for the 'url' parameter
              const encodedSrc = encodeURIComponent(decodedSrc);
              
              url.searchParams.set('url', encodedSrc);
            } else {
              // For files with spaces or special characters, we'll use the URL parameter approach
              // This is more reliable for complex filenames
              if (objectPathSegments.some(segment => segment.includes(' ') || /[^a-zA-Z0-9\/\-_.]/g.test(segment))) {
                url = new URL('https://getlitty.imgix.net');
                
                // First decode the URL to make sure we don't double-encode
                // This helps prevent mixed encoding issues (+ vs %20 for spaces)
                const decodedSrc = decodeURIComponent(src);
                
                // Manually create a proper URL-encoded string for the 'url' parameter
                const encodedSrc = encodeURIComponent(decodedSrc);
                
                url.searchParams.set('url', encodedSrc);
              } else {
                // For simple filenames, use direct path approach
                const objectPath = objectPathSegments.join('/');
                url = new URL(`https://getlitty.imgix.net/${objectPath}`);
              }
            }
          } catch (e) {
            // Fallback to URL parameter approach
            url = new URL('https://getlitty.imgix.net');
            
            // Ensure proper encoding
            const decodedSrc = decodeURIComponent(src);
            const encodedSrc = encodeURIComponent(decodedSrc);
            
            url.searchParams.set('url', encodedSrc);
          }
        }
      } else {
        // For other external URLs, use the URL parameter
        url = new URL('https://getlitty.imgix.net');
        
        // Ensure proper encoding
        const decodedSrc = decodeURIComponent(src);
        const encodedSrc = encodeURIComponent(decodedSrc);
        
        url.searchParams.set('url', encodedSrc);
      }
    } else {
      // For relative paths, append to the imgix domain
      const formattedSrc = src.startsWith('/') ? src : `/${src}`;
      url = new URL(`https://getlitty.imgix.net${formattedSrc}`);
    }

    // Set imgix parameters
    const params = url.searchParams;
    params.set('auto', params.getAll('auto').join(',') || 'format');
    params.set('fit', params.get('fit') || 'max');
    
    // Only set width if it's a positive number, otherwise use a reasonable default
    if (width && typeof width === 'number' && width > 0) {
      params.set('w', width.toString());
    } else if (!params.get('w')) {
      // If no width was specified and none exists, use a reasonable default (1000px)
      params.set('w', '1600');
    }

    if (quality) {
      params.set('q', quality.toString());
    }
    // console.log('Optimized image URL:', url.href);
    return url.href;
  } catch (e) {
    console.error('Error transforming image URL:', e, src);
    return src; // Return original URL as fallback
  }
} 