import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, TextField, Chip, Autocomplete } from '@mui/material';
import { Card } from 'flowbite-react';
import { Venue } from '../../../../shared/models';
import { getAttributeIcons } from '../../lib/firebase/metadata';

interface AttributeSelectionProps {
    data: Partial<Venue>;
    onNext: (venueData: Partial<Venue>) => void;
    place?: google.maps.places.PlaceResult | null;
    missingFields?: string[];
}

const AttributeSelection: React.FC<AttributeSelectionProps> = ({ data, onNext }) => {
    const [attributes, setAttributes] = useState<string[]>(Object.keys(data.Attributes || {}));
    const [neighborhoods, setNeighborhoods] = useState<string[]>(data.Neighborhoods || []);
    const [allAttributes, setAllAttributes] = useState<string[]>([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showAllAttributes, setShowAllAttributes] = useState(false);
    const [attributeInputValue, setAttributeInputValue] = useState('');
    const [neighborhoodInputValue, setNeighborhoodInputValue] = useState('');
    const [neighborhoodError, setNeighborhoodError] = useState<string>('');

    // Known neighborhoods list from AttributesTab
    const knownNeighborhoods = [
        'greenwich village', 'midtown', 'east village', 'queens', 'soho',
        'williamsburg', 'brooklyn', 'lower east side', 'lower manhattan',
        'flatiron', 'chelsea', 'upper east side', 'upper west side', 'tribeca',
        'financial district', 'chinatown', 'hell\'s kitchen', 'kips bay',
        'murray hill', 'gramercy', 'nomad', 'harlem', 'dumbo', 'bushwick',
        'astoria', 'long island city'
    ];

    useEffect(() => {
        const fetchAttributes = async () => {
            const icons = await getAttributeIcons();
            setAllAttributes(Object.keys(icons));
        };
        fetchAttributes();
    }, []);

    const handleAttributeChange = (_event: React.SyntheticEvent, newValue: string[]) => {
        setAttributes(Array.from(new Set(newValue)));
        setHasUnsavedChanges(true);
    };

    const handleAttributeInputChange = (_event: React.SyntheticEvent, newValue: string) => {
        setAttributeInputValue(newValue);
    };

    const handleAttributeKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && attributeInputValue.trim() !== '') {
            // Prevent form submission
            event.preventDefault();

            // Check if attribute already exists
            if (!attributes.includes(attributeInputValue.trim())) {
                setAttributes(prev => [...prev, attributeInputValue.trim()]);
                setHasUnsavedChanges(true);
                setAttributeInputValue('');
            }
        }
    };

    const handleNeighborhoodChange = (_event: React.SyntheticEvent, newValue: string[]) => {
        setNeighborhoods(Array.from(new Set(newValue)));
        setHasUnsavedChanges(true);
        setNeighborhoodError('');
    };

    const handleNeighborhoodInputChange = (_event: React.SyntheticEvent, newValue: string) => {
        setNeighborhoodInputValue(newValue);
    };

    const handleNeighborhoodKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && neighborhoodInputValue.trim() !== '') {
            // Prevent form submission
            event.preventDefault();

            // Check if neighborhood already exists
            if (!neighborhoods.includes(neighborhoodInputValue.trim())) {
                setNeighborhoods(prev => [...prev, neighborhoodInputValue.trim()]);
                setHasUnsavedChanges(true);
                setNeighborhoodInputValue('');
            }
        }
    };

    const handleSubmit = () => {
        // Validate neighborhoods
        if (!neighborhoods || neighborhoods.length === 0) {
            setNeighborhoodError('Please select at least one neighborhood');
            return;
        }

        const attributesObject = attributes.reduce((acc, attr) => ({ ...acc, [attr]: true }), {});
        onNext({
            ...data,
            Attributes: attributesObject,
            Neighborhoods: neighborhoods
        });
        setHasUnsavedChanges(false);
    };

    const toggleShowAllAttributes = () => {
        setShowAllAttributes(prev => !prev);
    };

    // Simplified chip style for selected items
    const selectedChipStyle = {
        m: 0.5,
        fontWeight: 500,
        textTransform: 'capitalize'
    };

    // Filter attributes that are not already selected
    const filteredAttributes = allAttributes.filter(attr => !attributes.includes(attr));
    // Determine how many attributes to display based on the toggle state
    const displayAttributes = showAllAttributes
        ? filteredAttributes
        : filteredAttributes.slice(0, 10);

    return (
        <Card className={`mt-4 bg-gray-800 border-0 shadow-xl ${hasUnsavedChanges ? 'ring-2 ring-amber-400' : ''}`}>
            {/* Attributes Section */}
            <section className="mb-6">
                <div className="flex items-center mb-2">
                    <div className="bg-purple-600 w-2 h-6 mr-2 rounded"></div>
                    <Typography variant="h5" className="font-bold text-white">Venue Attributes</Typography>
                </div>

                <p className="text-gray-300 text-sm mb-3">
                    Select attributes that describe your venue. Type to create custom attributes and press Enter to add.
                </p>

                <Autocomplete
                    multiple
                    freeSolo
                    options={allAttributes.filter(attr => !attributes.includes(attr))}
                    value={attributes}
                    onChange={handleAttributeChange}
                    inputValue={attributeInputValue}
                    onInputChange={handleAttributeInputChange}
                    onKeyDown={handleAttributeKeyDown}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip
                                variant="filled"
                                label={option}
                                {...getTagProps({ index })}
                                key={option}
                                sx={{
                                    ...selectedChipStyle,
                                    backgroundColor: '#7e57c2',
                                    color: 'white',
                                }}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Attributes"
                            placeholder="Add attribute and press Enter"
                            className="bg-gray-700 rounded-lg"
                            InputLabelProps={{
                                style: { color: 'rgba(255,255,255,0.7)' }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                style: { color: 'white' },
                                sx: {
                                    '& fieldset': { borderColor: 'rgba(255,255,255,0.3)' }
                                }
                            }}
                        />
                    )}
                />

                {allAttributes.length > 0 && (
                    <div className="mt-4 p-3 bg-gray-700/50 rounded-lg">
                        <div className="flex justify-between items-center mb-2">
                            <p className="text-amber-300 font-medium text-sm">POPULAR ATTRIBUTES</p>
                            {filteredAttributes.length > 10 && (
                                <Button
                                    onClick={toggleShowAllAttributes}
                                    size="small"
                                    className="text-purple-300 hover:text-purple-400 text-xs py-0.5 px-2"
                                    sx={{
                                        minWidth: 'unset',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: 'rgba(124, 77, 255, 0.1)' }
                                    }}
                                >
                                    {showAllAttributes ? 'Show Less' : 'Show All'}
                                </Button>
                            )}
                        </div>
                        <div className="flex flex-wrap gap-1">
                            {displayAttributes.map((attr) => (
                                <Chip
                                    key={attr}
                                    label={attr}
                                    onClick={() => {
                                        setAttributes((prev) => Array.from(new Set([...prev, attr])));
                                        setHasUnsavedChanges(true);
                                    }}
                                    sx={{
                                        backgroundColor: 'rgba(124, 77, 255, 0.2)',
                                        color: 'white',
                                        borderColor: 'rgba(124, 77, 255, 0.3)',
                                        m: 0.3,
                                        '&:hover': {
                                            backgroundColor: 'rgba(124, 77, 255, 0.4)',
                                        }
                                    }}
                                />
                            ))}
                            {!showAllAttributes && filteredAttributes.length > 10 && (
                                <span className="text-gray-400 self-center ml-2 text-xs">
                                    +{filteredAttributes.length - 10} more
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </section>

            <hr className="border-gray-700 my-4" />

            {/* Neighborhoods Section */}
            <section className="mb-6">
                <div className="flex items-center mb-2">
                    <div className="bg-amber-500 w-2 h-6 mr-2 rounded"></div>
                    <Typography variant="h5" className="font-bold text-white">Neighborhoods</Typography>
                </div>

                <p className="text-gray-300 text-sm mb-3">
                    Select neighborhoods where this venue is located or nearby. This helps with discovery. Type and press Enter to add custom neighborhoods.
                </p>

                <Autocomplete
                    multiple
                    freeSolo
                    options={knownNeighborhoods.filter(n => !neighborhoods.includes(n))}
                    value={neighborhoods}
                    onChange={handleNeighborhoodChange}
                    inputValue={neighborhoodInputValue}
                    onInputChange={handleNeighborhoodInputChange}
                    onKeyDown={handleNeighborhoodKeyDown}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip
                                variant="filled"
                                label={option}
                                {...getTagProps({ index })}
                                key={option}
                                sx={{
                                    ...selectedChipStyle,
                                    backgroundColor: '#f59e0b',
                                    color: '#111827',
                                }}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Neighborhoods"
                            placeholder="Add neighborhood and press Enter"
                            className="bg-gray-700 rounded-lg"
                            error={!!neighborhoodError}
                            helperText={neighborhoodError}
                            FormHelperTextProps={{
                                sx: {
                                    color: '#ef4444',
                                    marginLeft: '14px',
                                    marginTop: '4px'
                                }
                            }}
                            InputLabelProps={{
                                style: { color: 'rgba(255,255,255,0.7)' }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                style: { color: 'white' },
                                sx: {
                                    '& fieldset': {
                                        borderColor: neighborhoodError ? '#ef4444' : 'rgba(255,255,255,0.3)',
                                        borderWidth: neighborhoodError ? '2px' : '1px'
                                    }
                                }
                            }}
                        />
                    )}
                />

                <div className="mt-4 p-3 bg-gray-700/50 rounded-lg">
                    <p className="text-amber-300 font-medium mb-2 text-sm">COMMON NEIGHBORHOODS</p>
                    <div className="flex flex-wrap gap-1">
                        {knownNeighborhoods.filter(n => !neighborhoods.includes(n)).slice(0, 10).map((neighborhood) => (
                            <Chip
                                key={neighborhood}
                                label={neighborhood}
                                onClick={() => {
                                    setNeighborhoods((prev) => Array.from(new Set([...prev, neighborhood])));
                                    setHasUnsavedChanges(true);
                                }}
                                sx={{
                                    backgroundColor: 'rgba(245, 158, 11, 0.2)',
                                    color: 'white',
                                    borderColor: 'rgba(245, 158, 11, 0.3)',
                                    m: 0.3,
                                    '&:hover': {
                                        backgroundColor: 'rgba(245, 158, 11, 0.4)',
                                    }
                                }}
                            />
                        ))}
                    </div>
                </div>
            </section>

            {/* Action Button */}
            <div className="flex items-center justify-end gap-4 mt-2">
                {hasUnsavedChanges && (
                    <span className="text-amber-400 text-sm animate-pulse">
                        Unsaved changes
                    </span>
                )}
                <Button
                    onClick={handleSubmit}
                    className={`
                        min-w-[160px] font-bold px-6 py-2.5 rounded-lg
                        ${hasUnsavedChanges
                            ? 'bg-amber-500 hover:bg-amber-600 text-gray-900'
                            : 'bg-purple-600 hover:bg-purple-700 text-white'}
                        transition-all duration-300
                        ${hasUnsavedChanges ? 'animate-pulse' : ''}
                    `}
                    variant="contained"
                >
                    {hasUnsavedChanges ? 'Save Changes' : 'Continue'}
                </Button>
            </div>
        </Card>
    );
};

export default AttributeSelection;