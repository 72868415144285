import { create } from 'zustand';
import dayjs, { Dayjs } from 'dayjs';
import { useRouter } from 'next/navigation';

// Add improved debounce utility with proper typing
const debounce = <F extends (...args: any[]) => any>(func: F, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let lastArgs: Parameters<F> | null = null;
  
  const debouncedFunction = (...args: Parameters<F>): Promise<ReturnType<F> | undefined> => {
    lastArgs = args;
    
    return new Promise<ReturnType<F> | undefined>((resolve) => {
      const executeFunction = () => {
        timeout = null;
        if (lastArgs) {
          const result = func(...lastArgs);
          lastArgs = null;
          resolve(result);
        }
      };
      
      if (timeout) {
        clearTimeout(timeout);
      }
      
      timeout = setTimeout(executeFunction, wait);
    });
  };
  
  // Add a flush method to immediately execute if needed
  debouncedFunction.flush = () => {
    if (timeout && lastArgs) {
      clearTimeout(timeout);
      const result = func(...lastArgs);
      lastArgs = null;
      timeout = null;
      return result;
    }
  };
  
  // Add a cancel method
  debouncedFunction.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
      lastArgs = null;
    }
  };
  
  return debouncedFunction as typeof debouncedFunction & {
    flush: () => ReturnType<F> | undefined;
    cancel: () => void;
  };
};

// Add a flag to track if we're currently updating URL parameters
let isUpdatingParams = false;

interface SearchSettingsState {
  // Search parameters
  selectedDate: Dayjs | null;
  priceRange: string;
  people: string;
  zipCode: string;
  selectedAttributes: string[];
  selectedTime: string | null;
  neighborhoods: string[];
  // Internal state
  isDirty: boolean;
  
  // Actions
  setSelectedDate: (date: Dayjs | null) => void;
  setPriceRange: (priceRange: string) => void;
  setPeople: (people: string) => void;
  setZipCode: (zipCode: string) => void;
  setSelectedAttributes: (attributes: string[]) => void;
  toggleAttribute: (attribute: string) => void;
  setSelectedTime: (time: string | null) => void;
  updateSearchParams: () => void;
  debouncedUpdateSearchParams: () => void;
  initializeFromUrl: (searchParams: URLSearchParams) => void;
  reset: () => void;
  setNeighborhoods: (neighborhoods: string[]) => void;
  toggleNeighborhood: (neighborhood: string) => void;
}

export const useSearchSettingsStore = create<SearchSettingsState>((set, get) => {
  // Create a debounced version of updateSearchParams with longer debounce time
  const debouncedUpdateSearchParamsImpl = debounce(() => {
    // Skip if already updating parameters
    if (isUpdatingParams) {
      return;
    }
    
    isUpdatingParams = true;
    const state = get();
    
    // Use requestAnimationFrame to ensure we're not blocking the main thread
    window.requestAnimationFrame(() => {
      const searchParams = new URLSearchParams(window.location.search);

      if (state.selectedDate) {
        searchParams.set('date', state.selectedDate.toISOString().split('T')[0]);
      } else {
        searchParams.delete('date');
      }

      if (state.priceRange) {
        searchParams.set('price', state.priceRange);
      } else {
        searchParams.delete('price');
      }

      if (state.people) {
        searchParams.set('size', state.people);
      } else {
        searchParams.delete('size');
      }

      if (state.zipCode.length === 5) {
        searchParams.set('zip', state.zipCode);
      } else {
        searchParams.delete('zip');
      }

      if (state.selectedAttributes.length > 0) {
        searchParams.set('attributes', state.selectedAttributes.join(','));
      } else {
        searchParams.delete('attributes');
      }

      if (state.selectedTime) {
        searchParams.set('time', state.selectedTime);
      } else {
        searchParams.delete('time');
      }

      if (state.neighborhoods.length > 0) {
        searchParams.set('neighborhoods', state.neighborhoods.join(','));
      } else {
        searchParams.delete('neighborhoods');
      }

      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      
      // Using window.history.replaceState to update URL without page reload
      window.history.replaceState({}, '', newUrl);
      
      // Reset the dirty flag
      set({ isDirty: false });
      
      // Reset the updating flag
      isUpdatingParams = false;
    });
  }, 500); // Increased to 500ms for better UI responsiveness
  
  return {
    // Initial state
    selectedDate: null,
    priceRange: '',
    people: '',
    zipCode: '',
    selectedAttributes: [],
    selectedTime: null,
    neighborhoods: [],
    isDirty: false,
    
    // Optimized setters that batch updates together for better performance
    setSelectedDate: (date) => {
      set((state) => ({ 
        selectedDate: date,
        isDirty: true
      }));
      get().debouncedUpdateSearchParams();
    },
    
    setPriceRange: (priceRange) => {
      set((state) => ({ 
        priceRange,  
        isDirty: true
      }));
      get().debouncedUpdateSearchParams();
    },
    
    setPeople: (people) => {
      set((state) => ({ 
        people, 
        isDirty: true 
      }));
      get().debouncedUpdateSearchParams();
    },
    
    setZipCode: (zipCode) => {
      set((state) => ({ 
        zipCode, 
        isDirty: true 
      }));
      get().debouncedUpdateSearchParams();
    },
    
    setSelectedAttributes: (attributes) => {
      set((state) => ({ 
        selectedAttributes: attributes, 
        isDirty: true 
      }));
      get().debouncedUpdateSearchParams();
    },
    
    toggleAttribute: (attribute) => {
      set((state) => {
        const currentAttributes = state.selectedAttributes;
        const newAttributes = currentAttributes.includes(attribute)
          ? currentAttributes.filter(attr => attr !== attribute)
          : [...currentAttributes, attribute];
        
        return { 
          selectedAttributes: newAttributes, 
          isDirty: true 
        };
      });
      get().debouncedUpdateSearchParams();
    },
    
    setSelectedTime: (time) => {
      set((state) => ({ 
        selectedTime: time, 
        isDirty: true 
      }));
      get().debouncedUpdateSearchParams();
    },
    
    // Add neighborhood methods
    setNeighborhoods: (neighborhoods) => {
      set((state) => ({
        neighborhoods,
        isDirty: true
      }));
      get().debouncedUpdateSearchParams();
    },
    
    toggleNeighborhood: (neighborhood) => {
      set((state) => {
        const currentNeighborhoods = state.neighborhoods;
        const newNeighborhoods = currentNeighborhoods.includes(neighborhood)
          ? currentNeighborhoods.filter(n => n !== neighborhood)
          : [...currentNeighborhoods, neighborhood];
        
        return {
          neighborhoods: newNeighborhoods,
          isDirty: true
        };
      });
      get().debouncedUpdateSearchParams();
    },
    
    // Immediate update method (not debounced)
    updateSearchParams: () => {
      // Cancel any pending debounced updates
      debouncedUpdateSearchParamsImpl.cancel();
      
      // Execute the update immediately using the same logic
      const state = get();
      const searchParams = new URLSearchParams(window.location.search);

      if (state.selectedDate) {
        searchParams.set('date', state.selectedDate.toISOString().split('T')[0]);
      } else {
        searchParams.delete('date');
      }

      if (state.priceRange) {
        searchParams.set('price', state.priceRange);
      } else {
        searchParams.delete('price');
      }

      if (state.people) {
        searchParams.set('size', state.people);
      } else {
        searchParams.delete('size');
      }

      if (state.zipCode.length === 5) {
        searchParams.set('zip', state.zipCode);
      } else {
        searchParams.delete('zip');
      }

      if (state.selectedAttributes.length > 0) {
        searchParams.set('attributes', state.selectedAttributes.join(','));
      } else {
        searchParams.delete('attributes');
      }

      if (state.selectedTime) {
        searchParams.set('time', state.selectedTime);
      } else {
        searchParams.delete('time');
      }
      
      if (state.neighborhoods.length > 0) {
        searchParams.set('neighborhoods', state.neighborhoods.join(','));
      } else {
        searchParams.delete('neighborhoods');
      }

      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      
      // Using window.history.replaceState to update URL without page reload
      window.history.replaceState({}, '', newUrl);
      
      // Reset the dirty flag
      set({ isDirty: false });
    },
    
    debouncedUpdateSearchParams: () => {
      debouncedUpdateSearchParamsImpl();
    },
    
    initializeFromUrl: (searchParams) => {
      // Cancel any pending updates first
      debouncedUpdateSearchParamsImpl.cancel();
      
      console.log('searchParams', searchParams.get('neighborhoods'));
      const dateString = searchParams.get('date');
      const priceRangeString = searchParams.get('price');
      const peopleString = searchParams.get('size');
      const zipCodeString = searchParams.get('zip');
      const attributesString = searchParams.get('attributes');
      const timeString = searchParams.get('time');
      const neighborhoodsString = searchParams.get('neighborhoods');

      // Batch all changes in a single state update
      set({
        selectedDate: dateString ? dayjs(dateString) : null,
        priceRange: priceRangeString || '',
        people: peopleString || '',
        zipCode: zipCodeString || '',
        selectedAttributes: attributesString ? attributesString.split(',') : [],
        selectedTime: timeString || null,
        neighborhoods: neighborhoodsString ? neighborhoodsString.split(',') : [],
        isDirty: false
      });
    },
    
    reset: () => {
      // Cancel any pending updates first
      debouncedUpdateSearchParamsImpl.cancel();
      
      set({
        selectedDate: null,
        priceRange: '',
        people: '',
        zipCode: '',
        selectedAttributes: [],
        selectedTime: null,
        neighborhoods: [],
        isDirty: false
      });
    }
  };
}); 